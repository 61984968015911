import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import template from './NewsBanner.html';

export class NewsBanner extends Model {
    
    bannerClosed: boolean;
    updated_at: string;
    
    constructor( dto ) {
        super({ bannerClosed: false, ...dto });

        //show banner only if there was a change in the backend
        if (localStorage.getItem('mb_banner') && localStorage.getItem('mb_banner') === this.updated_at) {
            this.bannerClosed = true;
        }
        
    }

    closeBanner() {
        this.bannerClosed = true;
        localStorage.setItem( 'mb_banner', this.updated_at );
    }
}

export class NewsBannerView extends View<NewsBanner> {
    
    constructor(config?: ViewConfig<NewsBanner>) {
        super({ template, ...config });
    }

    init() {
    }
}