import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './LicenseElement.html';

import  ButtonImageTemplate  from './ButtonImage.html';


export class LicenseElement extends Model {
    
    text: string;

    constructor( dto ) {
        super({  
            ...dto });
    }
}

export class LicenseElementView extends View<LicenseElement> {
    
    constructor(config?: ViewConfig<LicenseElement>) {
        super({ template, ...config });
        this.registry.add( 'App\\ButtonImage', () => new View({template:ButtonImageTemplate } ) );
    }

    init() {
        
    }


}