import { Model, Signal, View } from '@lernetz/ts-lib';

import { asset,  route  } from '@lernetz/ts-lib/src/Setup';

import  template  from './QRCode.html'

declare class QRCode {
    constructor( n:Element );
    makeCode( value:string );
}

export class QRCodeView extends View {

    qrcode:QRCode;

    constructor() {
        super( { template } );
    }
    
    init() {
        if( !window['QRCode'] ) {
            this.loadLib();
        } else {
            this.renderCode();
        }
    }

    async loadLib() {
        await asset( 'qrcode' ).load();
        this.renderCode();
    }

    renderCode() {
        this.qrcode = new QRCode( this.node );
        this.qrcode.makeCode( this.data.value );
    }
}