import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './StartPage.html';


export class StartPage extends Model {

    scrolling: boolean;
    constructor( dto ) {
        super({
            scrolling: false,
            ...dto });
    }

}

export class StartPageView extends View<StartPage> {

    constructor(config?: ViewConfig<StartPage>) {
        super({ template, ...config });
    }

    init() {

        window.addEventListener('scroll', function(e) {
            this.data.scrolling = window.pageYOffset > 0 ? true : false;
        }.bind(this) );

        setTimeout( () => {
            let banner = this.node.querySelector('.news-banner:not(._hidden)') as HTMLElement;
            let celebration = this.node.querySelector('.celebration') as HTMLElement;
            celebration.style.bottom= banner?.offsetHeight ? banner.offsetHeight  + "px" : "40px";
        }, 100)

        // window.addEventListener('resize', this.onResize.bind(this));
        // this.onResize();

    }

    //if this is needed for edge suppert, use viewport ratio instead of clientWidth/Height
    // onResize() {
    //     this.node.querySelector('.video video').removeAttribute('style');


    //      if (this.node.querySelector('.video video').clientHeight < this.node.querySelector('.video video').clientWidth ) {
    //         this.node.querySelector('.video video').setAttribute('style', 'height:100vh')
    //     }
    //     else {
    //         this.node.querySelector('.video video').setAttribute('style', 'width:100vw')

    //     }
    // }

}
