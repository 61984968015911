import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './SongPage.html';
import { TutorialPage } from '../TutorialPage/TutorialPage';
import { observeProperties, dependProperty } from "@lernetz/ts-lib/src/Observer";

export class SongPage extends Model {

    title: string;
    slug:string;
    currentTutorial: TutorialPage;
    tutorials: TutorialPage[];
    selectedTutorialSlug: string;
    isNew: boolean;
    publicationDate: string;
    formatedPublicationDate: string;
    preselectedTutorialSlug: string;
    showGuestInfoBox: boolean;
    public: boolean;



    constructor( dto ) {
        super({ currentTutorial: null, preselectedTutorialSlug: null,  ...dto });
        this.currentTutorial = this.tutorials.find( tutorial => tutorial.slug === this.selectedTutorialSlug );

        // todo: include moment.js...
        if ( this.publicationDate ) {
             let temp = this.publicationDate.split(' ');
            temp = temp[0].split('-');
            this.publicationDate = temp[2] + '.' + temp[1] + '.'+ temp[0];
        }

        if (this.title && this.title.indexOf( '-' )> 0) {
            let titlePart = this.title.split(' - ');
            this.title = titlePart[0] + '<span class="text-style -medium"> - ' + titlePart[1] + '</span>'
        }

    }

    openGuestInfoBox(slug) {
        this.preselectedTutorialSlug = slug;
        this.showGuestInfoBox = true;
    }

    showTutorial( tutorial: TutorialPage) {
        this.currentTutorial = tutorial;
        window.history.pushState(this.slug, this.slug, "/song/" + this.slug + "/" + this.currentTutorial.slug  );
    }

}

export class SongPageView extends View<SongPage> {

    constructor(config?: ViewConfig<SongPage>) {
        super({ template, ...config });
    }

    init() {

    }


}
