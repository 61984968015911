import { Model, Signal, View } from '@lernetz/ts-lib';

import {Template} from '@lernetz/ts-lib/src/view/Template'
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import  template  from './RelatedContent.html';
import {Swipe}  from '../../../services/Swipe';
import { TutorialPage } from '../../Pages/TutorialPage/TutorialPage';

export class RelatedContent extends Model {
    
    
    position: number;
    completeStage: number;
    visibleStage: number;
    showRight: boolean;
    showLeft: boolean;
    step: number;
    selectedTutorial: TutorialPage;
    tutorials: TutorialPage[];

    constructor( dto ) {
        super({  
            position: 0,
            step: 280,
            selectedTutorial: undefined,
            tutorials: [],
            ...dto });
    }

    next() {
        this.position -= this.step;
        this.showLeft = true;
    }

    previous() {
        this.position += this.step;
        this.showRight = true;
    }

}

export class RelatedContentView extends View<RelatedContent> {
    
    constructor(config?: ViewConfig<RelatedContent>) {
        super({ template, data: new RelatedContent({}), ...config });
    }

    init() {
        setTimeout( ()=> {
            
            this.onResize();

            let style = getComputedStyle( this.node.querySelector('.inner-stage .item'));
            this.data.step = this.node.querySelector('.inner-stage .item').getBoundingClientRect().width + parseFloat( style.marginRight);

            
            window.addEventListener('resize', this.onResize.bind(this));
            let swiper = new Swipe( this.node.querySelector('.inner-stage') );
            swiper.onLeft( () => { this.data.next() });
            swiper.onRight( () => { this.data.previous() });
            swiper.run();
        }, 300)
        
        this.data.propertyChange.filter(({ name }) => name === 'position' ).subscribe( () => {
            if (this.data.completeStage + this.data.position <= this.data.visibleStage) {
                this.data.showRight = false;
            }
            if (this.data.position >= 0) {
                this.data.showLeft = false;
            }
        });
    }


    onResize() {
        this.data.completeStage = this.node.querySelector('.outer-stage').scrollWidth 
        this.data.visibleStage =  this.node.querySelector('.inner-stage').getBoundingClientRect().width;
        if (this.data.completeStage > this.data.visibleStage ) {
            this.data.showRight = true;
        }
    }
}