import { SpLinkBase, SpLinkBaseStyle } from './SpLinkBase';

export class SpLink extends SpLinkBase<'' | 'website-icon'> {

    public readonly url: string;

    public get defaultStyle(): SpLinkBaseStyle {
        return 'tertiary';
    }

    public get href() {
        return this.url;
    }
}
