import { Model, Signal, View } from '@lernetz/ts-lib';
import { route } from '@lernetz/ts-lib/src/Setup';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './ShopPage.html';


export class ShopPage extends Model {

    code: string;
    displayCodeSyntaxError: boolean;
    displayCodeInvalidError: boolean;

    constructor( dto ) {
        super({ code:'', displayCodeSyntaxError: false, displayCodeInvalidError:false, ...dto });
    }

    updateCode(e) {
        // only allow alphanumeric characters and dashes
        e.target.value = e.target.value.replace(/[^a-zA-Z0-9-]/g, "");
        this.code = e.target.value;
    }

    redirectCodeLicense() {
        this.code = this.code.split(' ').join(''); // ignore whitespace
        if (this.code.length != 14) {
            this.displayCodeSyntaxError = true;
        }
        else{
            route( 'validateAnyLicenseCode.post' )
                .send( JSON.stringify( {licenseCode: this.code} ) ).then( (response: any) => {

                    //todo check if license is valid
                    if (response.enabled === false || response.valid === false) {
                        this.displayCodeInvalidError = true;
                    }

                    else if (response.type === 'single') {
                        location.href = route('shopLicenseSingleUser', { licenseCode: this.code }).url();
                    }

                    else if (response.type === 'school') {
                        location.href = route('shopLicenseUser', { licenseCode: this.code }).url();
                    }

                });
        }


    }


}

export class ShopPageView extends View<ShopPage> {

    constructor(config?: ViewConfig<ShopPage>) {
        super({ template, ...config });
    }

    init() {

    }


}
