import { AudioPlayerItem } from '../components/Elements/AudioPlayer/AudioPlayerItem';

class AudioServiceProvider {
    
    public registered: AudioPlayerItem[];

    public constructor() {
        this.registered = [];
    }

    public register( audio: AudioPlayerItem ) {
        if (this.registered.indexOf(audio) < 0) {
            this.registered.push(audio);
            audio.audioView.addEventListener('ended', function() {
                this.pause(audio)
            }.bind(this));
            //todo: set some throttling... update every second
            audio.audioView.addEventListener("timeupdate", function() {
                audio.progress = Math.round( ( audio.audioView.currentTime/audio.audioView.duration ) * 100 );
                let minutes = "0" + Math.floor(audio.audioView.currentTime / 60);
                let seconds =  Math.floor(audio.audioView.currentTime % 60);
                let secondString = seconds < 10 ? "0" + seconds : seconds;
                audio.currentTimeString = minutes + ':' + secondString;
            }.bind(this));

            audio.audioView.addEventListener("loadstart", function() {
                audio.state = 'loading';
            }.bind(this));

            audio.audioView.addEventListener("canplay", function() {
                audio.state = 'playing'
            }.bind(this));

        }
    }

    public setCurrentTime(audio: AudioPlayerItem, setCurrentTime: number) {
        let time = audio.audioView.duration / 100 * setCurrentTime;
        audio.audioView.currentTime = time;
    }

    public play( audio: AudioPlayerItem ) {
        this.stopAll();
        audio.audioView.play();
        audio.state = 'playing'
    }

    public pause( audio: AudioPlayerItem ) {
        audio.audioView.pause();
        audio.state = 'stopped';
    }


    public stopAll():void {
        this.registered.forEach( (audio)=> {
            this.pause(audio);
        })
    }

}



export var AudioService = new AudioServiceProvider();