import { Model, Signal, View } from '@lernetz/ts-lib';
import { dependProperty } from '@lernetz/ts-lib/src/Observer';
import { data, route } from '@lernetz/ts-lib/src/Setup';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './ProfilePage.html';
import  owner  from './LicenseOwner.html';
import  user  from './LicenseUser.html';
import  department  from './LicenseDepartment.html';
import  singleUserCode  from './LicenseSingleUserCode.html';

import * as clipboard  from "clipboard-polyfill";

export class ProfilePage extends Model {

    newsletterEnabled: boolean;
    overlayOpen: boolean;
    isIOSDevice: boolean;
    checkboxIcon: string;
    licenseTemplate: {};
    maxLicenses: {};
    schoolSizeString: {};

    constructor( dto ) {
        super({ overlayOpen: false, checkboxIcon: data('license.newsletterEnabled') ? 'checkbox-active' : 'checkbox-default', newsletterEnabled:  data('license.newsletterEnabled'), ...dto });

        // max licenses per schoolSize (shown on profile page)
        this.maxLicenses = {
            '0' : 15,
            '1' : 30,
            '2' : 45,
            '3' : 1000
        }

        this.licenseTemplate = {
            'App\\LicenseCodeOwner' : 'LicenseOwner',
            'App\\LicenseCodeUser' : 'LicenseUser',
            'App\\LicenseDepartment' : 'LicenseDepartment',
            'App\\SingleUserLicenseCodeType' : 'LicenseSingleUserCode',
        }

        this.schoolSizeString = {
            '0' : 'kleine Schule',
            '1' : 'mittlere Schule',
            '2': 'grosse Schule',
            '3' : 'individuelle Gruppenlizenz'
        }

        this.isIOSDevice = navigator.userAgent.match(/ipad|iphone/i) ? true : false;
    }

    copyLicenseCode() {
        clipboard.writeText( data('license').code );
        this.overlayOpen = true;
    }

    toggleNewsletter() {
        this.newsletterEnabled = !this.newsletterEnabled;
        this.checkboxIcon = this.newsletterEnabled ? 'checkbox-active' : 'checkbox-default';
        route('setMauticNewsletter', {boolean: this.newsletterEnabled ? 'true' : 'false'}).load();
    }


}

export class ProfilePageView extends View<ProfilePage> {

    constructor(config?: ViewConfig<ProfilePage>) {
        super({ template, ...config });
        this.registry.add( 'LicenseOwner', () => new View({ template: owner}) );
        this.registry.add( 'LicenseUser', () => new View({ template: user}) );
        this.registry.add( 'LicenseDepartment', () => new View({ template: department}) );
        this.registry.add( 'LicenseSingleUserCode', () => new View({ template: singleUserCode}) );
    }

    init() {

    }


}
