import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './DownloadContainer.html';

import downloadListTemplate from './DownloadList.html';
import textItemTemplate from './TextItem.html';
import urlItemTemplate from './UrlItem.html';
import downloadItemTemplate from './downloadItem.html';
import { DownloadItemView } from './DownloadItem';

export class DownloadContainer extends Model {

    media: [];
    tipps: [];
    links: [];
    currentSelection: string;
    isPublicSong: boolean;
    constructor( dto ) {
        super({
            ...dto }
            );
        this.currentSelection = this.initialSelection;
    }

    get hasSingleTab() {
        let count = this.media.length > 0 ? 1 : 0;
        count += this.tipps.length > 0 ? 1 : 0;
        count += this.links.length > 0 ? 1 : 0;
        return count === 1;
    }

    get initialSelection() {
        if (this.media && this.media.length > 0) return 'media';
        if (this.tipps && this.tipps.length > 0) return 'tipps';
        if (this.links && this.links.length > 0) return 'links';
    }

    select( name: string) {
        this.currentSelection = name;
    }
}

export class DownloadContainerView extends View<DownloadContainer> {

    constructor(config?: ViewConfig<DownloadContainer>) {
        super({ template, ...config });
        this.registry.add( 'App\\TextItem', () => new View({template:textItemTemplate } ) );
        this.registry.add( 'App\\UrlItem', () => new View({template:urlItemTemplate } ) );
        this.registry.add( 'App\\DownloadItem', () => new DownloadItemView() );
    }

    init() {

    }


}
