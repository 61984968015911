import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import template from './SpLine.html';

export class SpLine extends Model {



    constructor( dto ) {
        super({ ...dto });
    }
}

export class SpLineView extends View<SpLine> {

    constructor(config?: ViewConfig<SpLine>) {
        super({ template, ...config });
    }

    async init() {
    }

}
