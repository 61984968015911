import { ViewConfig } from "@lernetz/ts-lib/src/view/View";
import template from "./Rive.html";
import { Model, View } from "@lernetz/ts-lib";
import { asset, route } from "@lernetz/ts-lib/src/Setup";

declare var rive: any;

export class Rive extends Model {

    public file: string;
    public stateMachine: string = "State Machine 1";

    constructor( dto = {} ) {
        super({ ...dto });
    }
}

export class RiveView extends View<Rive> {

    constructor() {
        super({ template, data: new Rive() });
    }

    async init() {

        await asset('rive').load();

        const r = new rive.Rive({
            src: route("rive", { file: this.data.file }).url(),
            canvas: this.node.querySelector("canvas"),
            autoplay: true,
            stateMachines: this.data.stateMachine,
            onLoad: () => {
              r.resizeDrawingSurfaceToCanvas();
            },
          });

    }

}
