import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import template from './SpVideo.html';

export class SpVideo extends Model {

    text: string;
    size: string;
    position: string;

    constructor( dto ) {
        super({ ...dto });
    }
}

export class SpVideoView extends View<SpVideo> {

    constructor(config?: ViewConfig<SpVideo>) {
        super({ template, ...config });
    }

    async init() {
    }

}
