
import { setup, scan } from '@lernetz/ts-lib';
import { data } from '@lernetz/ts-lib/src/Setup';
import { context } from '@lernetz/ts-lib/src/view/Template';

import { modelRegistry, viewRegistry } from './register';




export function init( setupData ) {



        setup(setupData, modelRegistry);

        context.availableSinglePages = setupData.data?.availableSinglePages ?? [];

        setTimeout( () => {
                if ( data('firstLogin') && window['plausible']) {
                        window['plausible']('Anmeldung', { props: {Kanton: data('department'), Lizenz: data('license').replace('App\\', '') } });
                        // console.log('first')
                }
                else {
                        // console.log('not first')
                }
        }, 500)

        //context.logging = true;
        scan({ root: document.body, data: setupData.data, registry: viewRegistry } );


}
