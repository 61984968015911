import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';


import  template  from './LibraryPage.html';
import { TutorialPage } from '../TutorialPage/TutorialPage';
import { SongPage } from '../SongPage/SongPage';

import { Element } from '../../../interfaces/Element';

import Header from '../../../services/Header';
import { dependProperty, observeProperties } from '@lernetz/ts-lib/src/Observer';
import { data, route } from '@lernetz/ts-lib/src/Setup';


export class LibraryPage extends Model {

    selectedPage: string;
    showSticky: boolean;
    // scrollToElementId: string;
    elements: Element[];
    songs: SongPage[];
    tutorials: TutorialPage[];
    selectedTutorial: TutorialPage;
    selectedTab: string;
    selectedTags: {data: string, label: string}[];
    filteredTutorials: TutorialPage[];
    showGuestInfoBox: boolean;


    constructor( dto ) {
        super({
            // showSticky: false,
            selectedTutorial: undefined,
            selectedTab: 'songs',
            selectedTags: [],
            filteredTutorials: [],
            tutorials:[],
            showGuestInfoBox: false,
            ...dto
        });

        // filter out public/free songs for customers
        if (data('isCustomer')) {
            this.songs = this.songs.filter( (song) => {
                return song.public != true;
            })
        }

        //make sure the favorites are updated after opening a general tutorial page
        dependProperty( this, 'favorites', this, 'selectedTutorial' );

        //remove tutorial from favorites (after toggling favorite on favorite-tab)
        // this.tutorials.forEach( tutorial => {
        //     dependProperty( this, 'favorites', tutorial, 'favorite' );
        // })

        //make sure the favorites are updated after toggling favorite on general-tutorial tab
        dependProperty( this, 'favorites', this, 'selectedTab' );

        this.filteredTutorials = this.generalTutorials;
        this.selectedPage = 'library';

        this.propertyChange.filter( (e) => e.name === 'selectedTags' ).subscribe( (e) => {
            if (this.selectedTags.length === 0 )  {
                this.filteredTutorials = this.generalTutorials;
            }
            else {
                this.filteredTutorials = [];
                this.generalTutorials.forEach( (tutorial) => {
                    tutorial.tags.forEach( (tutorialTag) => {
                        this.selectedTags.forEach( (selectedTag) => {
                            if (this.filteredTutorials.indexOf(tutorial) > -1 ) return
                            if (selectedTag.data === tutorialTag) this.filteredTutorials.push(tutorial) ;
                        })
                    })
                })
            }
        })





        //this.scrollToElementId = this.elements.find( (element)=> element.modelName = 'App\\Carousel').id;
        //temporary disabled
        // this.scrollToElementId = null;

        //  this.songs = this.songs.sort( (a, b) => {
        //     if ( a.isNew ) return -1;
        //     if (b.isNew) return 1;
        //     if (!a.isNew && !a.comingSoon ) return -1;
        //     if (!b.isNew && !b.comingSoon ) return 1;
        //     if (a.comingSoon === true ) return -1;
        // });

    }

    selectTutorial( tutorial ) {
        if (data('isCustomer') || tutorial.public) {
            this.selectedTutorial = tutorial;
        }
        else {
            this.showGuestInfoBox = true;
        }

    }

    goToTutorial( tutorial ) {
        location.href = route( 'song', {songSlug: tutorial.songSlug, tutorialSlug: tutorial.slug}).url()
    }

    updateSearch(tag) {
        this.selectedTags.splice(this.selectedTags.indexOf(tag), 1) ;
        this.propertyChange.dispatch({name: 'selectedTags'})
    }


    addTag(tag) {
        if (tag === null || this.selectedTags.indexOf(tag) > -1 ) return
        this.selectedTags.push(tag)
        this.propertyChange.dispatch({name: 'selectedTags'})
    }

    get generalTutorials() {
        let tutorials = this.tutorials.filter( tutorial => tutorial.type === 'general' );
        if (!data('isCustomer')) {
            tutorials.sort( (a, b) => {
                return (a.public === b.public) ? 0 : a.public? -1 : 1;
            })

        }
        return tutorials;
    }

    get favorites() {
        let favorites = this.tutorials.filter( tutorial => tutorial.favorite === true ) as TutorialPage[]

         return favorites.sort( ( a, b ) => {
            let value1 = a.favoriteUpdatedAt ? a.favoriteUpdatedAt : 0;
            let value2 = b.favoriteUpdatedAt ? b.favoriteUpdatedAt : 0;
            return value2 - value1;
        });

    }

    get dropDownTags() {
        let tags = [];
        let dropDown = [];
        this.tutorials.forEach( (tutorial) => {
            tutorial.tags.map( (tag) => {
                if (tags.indexOf(tag) === -1) {
                    tags.push(tag);
                    dropDown.push({
                        label: tag,
                        data: tag,
                    })
                }
            })
        })
        // dropDown.push({label: 'Filter löschen', data: 'none' });
        return dropDown;
    }



}

export class LibraryPageView extends View<LibraryPage> {

    constructor(config?: ViewConfig<LibraryPage>) {
        super({ template, ...config });
    }

    init() {

        //22.6.22: keine ahnung wozu das war...

        //make header sticky and remove hamburger menu on mobile down-scrolling
        // setTimeout( ()=> {
        //     new Header( this );
        // }, 100)

        //make sure we are on top of the page
        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
          }

        //remove welcome banner if scrolled out of view
        document.addEventListener('scroll', () => {
            let banner = this.node.querySelector('.welcome-banner') as HTMLElement;
            let rect = banner.getBoundingClientRect();
            let isVisible = rect.top < -94;
            if (rect.top < -94) {
                banner.style.display = 'none';
            }
        })





    }


}
