import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import { route } from '@lernetz/ts-lib/src/Setup';


import  template  from './MailChimpSlide.html';
import { ImageView as ImageHelper } from '@lernetz/ts-lib/src/components/Image';


import {Asset} from '../../../interfaces/Asset';

export class MailChimpSlide extends Model {
    
    slides: [];
    background: Asset;
    subscribed: boolean;
    

    constructor( dto ) {
        super({  subscribed: false, ...dto });
    }
}

export class MailChimpSlideView extends View<MailChimpSlide> {
    
    constructor(config?: ViewConfig<MailChimpSlide>) {
        super({ template, ...config });
        
        
    }




    init() {
        if (this.data.background) {
            this.node.setAttribute('style', 'background-image:url( ' +  route( 'asset', {file_name: this.data.background.file_name, ext: this.data.background.ext, preset: 'original'}).url() + ' ) ');
        }

        let input = this.node.querySelector('.input') as HTMLFormElement;
        input.addEventListener( 'focus', this.onFocus.bind(this))
        input.addEventListener( 'keydown', this.onKeydown.bind(this))
        
    };
    
    onFocus( e ){
        if ( e.target.textContent === 'E-Mail' ) {
            e.target.textContent = '';
        }
        this.context.parent.parent.data.pause();
        this.node.querySelector('.message').textContent = '';
        this.node.querySelector('.message').classList.add('_hidden');
    }

    onKeydown(e) {
        if ( e.which === 13 || e.keyCode === 13 ) {
            this.submit();
            e.preventDefault();
        }
        
    }

    submit() {

        if (this.data.subscribed === true ) {
            this.node.querySelector('.message').textContent = 'Vielen Dank. Sie haben sich bereits einen Newsletter registriert';
            return;
        }

        let mail = this.node.querySelector('.input').innerHTML;
         
        if ( this.validateEmail(mail) ) {
            let data = {email: mail};
            route('subscribe').send(JSON.stringify( data ) ).then( (response:{status: boolean})=> {
                if ( response.status ) {
                    this.node.querySelector('.message').textContent = 'Anmeldung erfolgreich..';
                    this.data.subscribed = true;
                    this.node.querySelector('.message').classList.remove('_hidden')
                }
                else {
                    this.node.querySelector('.message').textContent = 'Irgendetwas ist schief gelaufen ...';
                    this.node.querySelector('.message').classList.remove('_hidden')
                }
            })
        }
        else {
            this.node.querySelector('.message').textContent = 'Bitte gültige E-mail Adresse angeben';
            this.node.querySelector('.message').classList.remove('_hidden')
        }

        
    }


    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }




    // submit() {
    //     let mail = this.node.querySelector('.input').innerHTML;
    //     if ( this.validateEmail(mail) ) {
    //         let data = {email: mail};
    //         route('subscribe').send(JSON.stringify( data ) ).then( (response:{status: boolean})=> {
    //             if ( response.status ) {
    //                 this.node.querySelector('.message').innerHTML = 'Anmeldung erfolgreich..';
    //             }
    //             else {
    //                 this.node.querySelector('.message').innerHTML = 'Anmeldung nicht erfolgreich..';
    //             }
    //         })
    //     }
    //     else {
    //         this.node.querySelector('.message').innerHTML = 'Bitte gültige E-mail Adresse angeben';
    //     }
    // }


    // validateEmail(email) {
    //     const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return re.test(String(email).toLowerCase());
    // }
}