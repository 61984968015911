import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import template from './ExternalScript.html';

export class ExternalScript extends Model {
    
    url: string;

    constructor( dto ) {
        super({ ...dto });
    }
}

export class ExternalScriptView extends View<ExternalScript> {
    
    constructor(config?: ViewConfig<ExternalScript>) {
        super({ template, ...config });
        
    }

    async init() {
        
        await new Promise<void>( ( resolve, reject ) => {
            var element = document.createElement( 'script' );
            element.src = this.data.url;
            element.addEventListener( 'load', event => resolve() );
            element.addEventListener( 'error', event => reject() );
            
            this.node.appendChild( element );
            
        } );
    }

}