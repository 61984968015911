import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './AudioPlayer.html';
import  {AudioPlayerItemView}  from './AudioPlayerItem';



export class AudioPlayer extends Model {
    
    

    constructor( dto ) {
        super({  
            ...dto });
    }
}

export class AudioPlayerView extends View<AudioPlayer> {
    
    constructor(config?: ViewConfig<AudioPlayer>) {
        super({ template, ...config });
        this.registry.add( 'AudioPlayerItem', () => new AudioPlayerItemView );
    }

    init() {
        
    }


}