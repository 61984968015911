import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './StudentPage.html';


export class StudentPage extends Model {
    audioPlayer: any[];
    elements: any[];
    constructor( dto ) {
        super({  ...dto });
        if (this.elements) {
            this.audioPlayer = this.elements.find( (element)=> element.modelName === 'App\\AudioPlayer' )
        }
    }
}

export class StudentPageView extends View<StudentPage> {
    
    constructor(config?: ViewConfig<StudentPage>) {
        super({ template, ...config });
    }

    init() {
        
    }


}