import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import template from './SpCol.html';

export class SpCol extends Model {



    constructor( dto ) {
        super({ ...dto });

    }
}

export class SpColView extends View<SpCol> {

    constructor(config?: ViewConfig<SpCol>) {
        super({ template, ...config });
    }

    async init() {
    }

}
