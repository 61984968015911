import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './SponsorBlock.html';
import  imageTemplate  from './ImageContainer.html';
import textTemplate from './Text.html'


export class SponsorBlock extends Model {
    
    text: string;

    constructor( dto ) {
        super({  
            ...dto });
    }
}

export class SponsorBlockView extends View<SponsorBlock> {
    
    constructor(config?: ViewConfig<SponsorBlock>) {
        super({ template, ...config });
        this.registry.add( 'App\\Text', () => new View({template:textTemplate } ) );
        this.registry.add( 'App\\ImageContainer', () => new View({template:imageTemplate } ) );

    }

    init() {
        
    }


}