import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import { route } from "@lernetz/ts-lib/src/Setup";
import  template  from './SplashPage.html';


export class SplashPage extends Model {
    

    constructor( dto ) {
        super({  ...dto });
    }
}

export class SplashPageView extends View<SplashPage> {
    
    constructor(config?: ViewConfig<SplashPage>) {
        super({ template, ...config });
    }

    init() {
        // let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        // let videoFile =  (vw >= 1280 ) ? 'splash_desktop.mp4' : 'splash_mobile.mp4';
        let videoFile =  'splash.mp4';
        let video = document.createElement('video') as any; //there is a bug in typescript...
        
        video.playsInline = true;
        video.autoplay = true; 
        // video.loop = true;
        video.muted = true;


        this.node.querySelector('.video').appendChild(video);
        video.src = route( 'image', { file_name: videoFile, preset: 'original', ext: 'mp4'} ).url();
        
        document.querySelector('body').setAttribute('style', 'position:fixed')


        //TODO
        setTimeout( ()=> {
            video.addEventListener('ended', (event) => {
                document.querySelector('body').removeAttribute('style')
                this.node.classList.add('-close')

                setTimeout( () => {
                    this.node.classList.add('-faded')
                }, 800)

            });
        }, 100)




    }


}