import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import template from './HardCodedView.html';
import PricingTemplate from './Pricing.html';

export class HardCodedView extends Model {
    
    view: string;

    pricingStepsOpen: boolean;

    constructor( dto ) {
        super({ pricingStepsOpen: false, template, ...dto });
    }
}

export class HardCodedViewView extends View<HardCodedView> {
    
    constructor(config?: ViewConfig<HardCodedView>) {
        super({ template, ...config });
        this.registry.add( 'Pricing', () => new View({template:PricingTemplate } ) );
    }

    init() {
        //enable deeplinking to '#school-license' and '#department-license'
        if (location.hash) {
            this.node.querySelector(location.hash).scroll()
        }
        
    }

    openPricingSteps() {

    }


}