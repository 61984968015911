import { ViewRegistry, ModelRegistry, View } from '@lernetz/ts-lib';

import { Html } from '@lernetz/ts-lib/src/components/Html';
import { Repeat } from '@lernetz/ts-lib/src/components/Repeat';
import { Show } from '@lernetz/ts-lib/src/components/Show';
import { ImageView as ImageHelper } from '@lernetz/ts-lib/src/components/Image';
import { HtmlView } from '@lernetz/ui-components/src/html/Html';


import { ImageView as UiImage } from '@lernetz/ui-components/src/image/Image';
import { DropDown, DropDownView } from '@lernetz/ui-components/src/drop-down/DropDown';
import DropDownTemplate from './components/Pages/LibraryPage/DropDown.html';

// import {  BurgerMenuIcon } from '@lernetz/ui-components/src/burger-menu-icon/BurgerMenuIcon';


import {Carousel, CarouselView} from './components/Elements/Carousel/Carousel';

import {ContainerPage, ContainerPageView} from './components/Pages/ContainerPage/ContainerPage';
import {StartPage, StartPageView} from './components/Pages/StartPage/StartPage';
import {SongPage, SongPageView} from './components/Pages/SongPage/SongPage';
import {LibraryPage, LibraryPageView} from './components/Pages/LibraryPage/LibraryPage';
import {SinglePage, SinglePageView} from './components/Pages/SinglePage/SinglePage';
import {LandingPage, LandingPageView} from './components/Pages/LandingPage/LandingPage';
import {StudentPage, StudentPageView} from './components/Pages/StudentPage/StudentPage';
import {TutorialPreview, TutorialPreviewView  } from './components/TutorialPreview/TutorialPreview';
import TutorialPreviewComingSoonTemplate from './components/TutorialPreview/TutorialPreviewComingSoon.html';
import {TutorialPage, TutorialPageView} from './components/Pages/TutorialPage/TutorialPage';
import {ShopPage, ShopPageView} from './components/Pages/ShopPage/ShopPage';
import {ProfilePage, ProfilePageView} from './components/Pages/ProfilePage/ProfilePage';
import {SplashPage, SplashPageView} from './components/Pages/SplashPage/SplashPage';
import {Video, VideoView, LoopVideoView} from './components/Video/Video';
import { IframelyView } from './components/Iframely/Iframely'
import { RelatedContent, RelatedContentView } from './components/Elements/RelatedContent/RelatedContent';
import { BackgroundBlock, BackgroundBlockView} from './components/Elements/BackgroundBlock/BackgroundBlock';
import { ContentBlock, ContentBlockView } from './components/Elements/ContentBlock/ContentBlock';
import { HardCodedView, HardCodedViewView } from './components/Elements/HardCodedView/HardCodedView';
import { ExternalScript, ExternalScriptView } from './components/Elements/ExternalScript/ExternalScript';
import { NewsBanner, NewsBannerView } from './components/Elements/NewsBanner/NewsBanner';
import { FadingImages, FadingImagesView } from './components/Elements/FadingImages/FadingImages';
import { SpTitle, SpTitleView } from './components/Elements/SpTitle/SpTitle';
import { SpParagraph, SpParagraphView } from './components/Elements/SpParagraph/SpParagraph';
import { SpLine, SpLineView } from './components/Elements/SpLine/SpLine';
import { SpLink } from './components/Elements/SpLink/SpLink';
import { SpDownload } from './components/Elements/SpDownload/SpDownload';
import { SpLinkBaseView } from './components/Elements/SpLink/SpLinkBase';
import { SpImage, SpImageView } from './components/Elements/SpImage/SpImage';
import { SpVideo, SpVideoView } from './components/Elements/SpVideo/SpVideo';
import { SpCol, SpColView } from './components/Elements/SpCol/SpCol';



import { SponsorBlock, SponsorBlockView } from './components/Elements/SponsorBlock/SponsorBlock';
import { NewsletterElement, NewsletterElementView } from './components/Elements/NewsletterElement/NewsletterElement';
import {SimpleSlide, SimpleSlideView} from './components/Elements/Carousel/SimpleSlide';
import {MailChimpSlide, MailChimpSlideView} from './components/Elements/Carousel/MailChimpSlide';
import {TextImage, TextImageView} from './components/Elements/TextImage/TextImage';
import {DownloadContainer, DownloadContainerView} from './components/Elements/DownloadContainer/DownloadContainer';
import {LicenseElement, LicenseElementView} from './components/Elements/LicenseElement/LicenseElement';
import {LibraryPreview, LibraryPreviewView} from './components/Elements/LibraryPreview/LibraryPreview';
import {AudioPlayer, AudioPlayerView} from './components/Elements/AudioPlayer/AudioPlayer';
import {AudioPlayerItem, AudioPlayerItemView} from './components/Elements/AudioPlayer/AudioPlayerItem';
import {AboutBox, AboutBoxView} from './components/Elements/AboutBox/AboutBox';
import {ModalElement, ModalElementView} from './components/Elements/ModalElement/ModalElement';

import { QRCodeView } from './services/QRCode';
import { RiveView } from './components/Elements/Rive/Rive';


export var viewRegistry = new ViewRegistry();
viewRegistry.add('repeat', () => new Repeat());
viewRegistry.add('show', () => new Show());
viewRegistry.add('html', () => new Html());
viewRegistry.add('HtmlView', () => new HtmlView());

// viewRegistry.add('BurgerMenuIcon', () => new BurgerMenuIcon());



viewRegistry.add( 'Image', () => new ImageHelper() );

viewRegistry.add( 'UiImage', () => new UiImage() );
viewRegistry.add( 'DropDown', () => new DropDownView( {template: DropDownTemplate}) );

viewRegistry.add('ContainerPage', () => new ContainerPageView());
viewRegistry.add(StartPage, () => new StartPageView());
viewRegistry.add(SongPage, () => new SongPageView());
viewRegistry.add(LibraryPage, () => new LibraryPageView());
viewRegistry.add(TutorialPage, () => new TutorialPageView());
viewRegistry.add(SinglePage, () => new SinglePageView());
viewRegistry.add(LandingPage, () => new LandingPageView());
viewRegistry.add(StudentPage, () => new StudentPageView());
viewRegistry.add(ShopPage, () => new ShopPageView());
viewRegistry.add(ProfilePage, () => new ProfilePageView());
viewRegistry.add('SplashPage', () => new SplashPageView());
viewRegistry.add('TutorialPreview', () => new TutorialPreviewView());
viewRegistry.add('TutorialPreviewComingSoon', () => new TutorialPreviewView( {
    template: TutorialPreviewComingSoonTemplate
} ) );

viewRegistry.add('Video', () => new VideoView());
viewRegistry.add('LoopVideo', () => new LoopVideoView());
viewRegistry.add('Iframely', () => new IframelyView());
viewRegistry.add(RelatedContent, () => new RelatedContentView());
viewRegistry.add("RelatedContent", () => new RelatedContentView());
viewRegistry.add(ContentBlock, () => new ContentBlockView());
viewRegistry.add(HardCodedView, () => new HardCodedViewView());
viewRegistry.add(ExternalScript, () => new ExternalScriptView());
viewRegistry.add('ExternalScript', () => new ExternalScriptView());
viewRegistry.add(NewsBanner, () => new NewsBannerView());
viewRegistry.add('FadingImages', () => new FadingImagesView());

viewRegistry.add(SpTitle, () => new SpTitleView());
viewRegistry.add(SpParagraph, () => new SpParagraphView());
viewRegistry.add(SpLine, () => new SpLineView());
viewRegistry.add(SpDownload, data => new SpLinkBaseView<SpDownload>( { data } ));
viewRegistry.add(SpLink, data => new SpLinkBaseView<SpLink>( { data } ));
viewRegistry.add(SpImage, () => new SpImageView());
viewRegistry.add(SpVideo, () => new SpVideoView());
viewRegistry.add(SpCol, () => new SpColView());




viewRegistry.add(NewsletterElement, () => new NewsletterElementView());
viewRegistry.add(BackgroundBlock, () => new BackgroundBlockView());
viewRegistry.add(SponsorBlock, () => new SponsorBlockView());



viewRegistry.add(Carousel, () => new CarouselView());

viewRegistry.add(SimpleSlide, () => new SimpleSlideView());
viewRegistry.add(MailChimpSlide, () => new MailChimpSlideView());
viewRegistry.add(TextImage, () => new TextImageView());
viewRegistry.add(DownloadContainer, () => new DownloadContainerView());
viewRegistry.add(LicenseElement, () => new LicenseElementView());
viewRegistry.add(LibraryPreview, () => new LibraryPreviewView());
viewRegistry.add(AudioPlayer, () => new AudioPlayerView());
viewRegistry.add(AboutBox, () => new AboutBoxView());
viewRegistry.add('ModalElement', () => new ModalElementView());
viewRegistry.add('Rive', () => new RiveView() );

viewRegistry.add('QrCode', ()=> new QRCodeView() );
viewRegistry.add( "default", () => new View( { template:'<div>Fallback view</div>' } ) );
viewRegistry.add( Object, () => new View({ template:'!!! Element nicht registriert !!!' }) );





export var modelRegistry = new ModelRegistry();

modelRegistry.add('App\\StartPage', dto => new StartPage(dto));
modelRegistry.add('App\\Song', dto => new SongPage(dto));
modelRegistry.add('App\\LibraryPage', dto => new LibraryPage(dto));
modelRegistry.add('App\\Tutorial', dto => new TutorialPage(dto));
modelRegistry.add('App\\SinglePage', dto => new SinglePage(dto));
modelRegistry.add('App\\LandingPage', dto => new LandingPage(dto));
modelRegistry.add('StudentPage', dto => new StudentPage(dto));
modelRegistry.add('ShopPage', dto => new ShopPage(dto));
modelRegistry.add('ProfilePage', dto => new ProfilePage(dto));
modelRegistry.add('App\\RelatedContent', dto => new RelatedContent(dto));
modelRegistry.add('App\\ContentBlock', dto => new ContentBlock(dto));
modelRegistry.add('App\\NewsletterElement', dto => new NewsletterElement(dto));
modelRegistry.add('App\\BackgroundBlock', dto => new BackgroundBlock(dto));
modelRegistry.add('App\\SponsorBlock', dto => new SponsorBlock(dto));
modelRegistry.add('App\\HardCodedView', dto => new HardCodedView(dto));
modelRegistry.add('App\\ExternalScript', dto => new ExternalScript(dto));

modelRegistry.add('App\\SpTitle', dto => new SpTitle(dto));
modelRegistry.add('App\\SpParagraph', dto => new SpParagraph(dto));
modelRegistry.add('App\\SpLine', dto => new SpLine(dto));
modelRegistry.add('App\\SpDownload', dto => new SpDownload(dto));
modelRegistry.add('App\\SpLink', dto => new SpLink(dto));
modelRegistry.add('App\\SpImage', dto => new SpImage(dto));
modelRegistry.add('App\\SpVideo', dto => new SpVideo(dto));
modelRegistry.add('App\\SpCol', dto => new SpCol(dto));

//modelRegistry.add('App\\Carousel', dto => new MyCarousel(dto));
modelRegistry.add('App\\Carousel', dto => new Carousel(dto));

modelRegistry.add('App\\SimpleSlide', dto => new SimpleSlide(dto));
modelRegistry.add('App\\MailChimpSlide', dto => new MailChimpSlide(dto));
modelRegistry.add('App\\TextImage', dto => new TextImage(dto));
modelRegistry.add('App\\DownloadContainer', dto => new DownloadContainer(dto));
modelRegistry.add('App\\LicenseElement', dto => new LicenseElement(dto));
modelRegistry.add('App\\LibraryPreview', dto => new LibraryPreview(dto));
modelRegistry.add('App\\AudioPlayer', dto => new AudioPlayer(dto));
modelRegistry.add('App\\Audio', dto => new AudioPlayerItem(dto));
modelRegistry.add('App\\AboutBox', dto => new AboutBox(dto));
modelRegistry.add('SplashPage', dto => new SplashPage(dto));
modelRegistry.add('App\\NewsBanner', dto => new NewsBanner(dto));
modelRegistry.add('App\\FadingImages', dto => new FadingImages(dto));

