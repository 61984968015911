import { Model, View } from "@lernetz/ts-lib";

import template from './BackgroundBlock.html';
import { Asset } from "../../../interfaces/Asset";

export class BackgroundBlock extends Model{
    constructor( dto ) {
        super({  
            ...dto });
    }
}

export class BackgroundBlockView extends View<BackgroundBlock>{
    constructor(){
        super({
            template:template
        });
    }
}