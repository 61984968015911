import { Model, Signal, View, setup } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import { route } from "@lernetz/ts-lib/src/Setup";

import  customerHeaderTemplate  from './CustomerHeader.html';
import  guestHeaderTemplate  from './GuestHeader.html';


export class Header extends Model {
    
    hamburgerOpen: boolean;
    profileMenuOpen: boolean;
    profilePageOpen: boolean;
    

    constructor( dto ) {
        super({  
            ...dto });
    }
}
