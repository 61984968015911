
import { route } from "@lernetz/ts-lib/src/Setup";

import { Model, View } from "@lernetz/ts-lib";

import template from './Video.html';

import loopVideoTemplate from './LoopVideo.html';
import { Asset } from "../../interfaces/Asset";


export class Video extends Model {
    video: Asset;
    poster: Asset;
}


export class VideoView extends View<Video> {

	constructor() {
        super( { template, data:new Video( { route:'' } ) } );
	}

	init() {
                this.node.querySelector('video').setAttribute( "src", route( 'asset', { file_name: this.data.video.file_name, preset: 'original', ext: this.data.video.ext} ).url() );
                this.node.querySelector('video').setAttribute( "poster", route( 'asset', { file_name: this.data.poster.file_name, preset: 'original', ext: this.data.poster.ext} ).url() );
	}
}


export class LoopVideoView extends View<Video> {

	constructor() {
        super( { template: loopVideoTemplate, data:new Video( { route:'' } ) } );
	}

	init() {
                this.node.querySelector('video').setAttribute( "src", route( 'asset', { file_name: this.data.video.file_name, preset: 'original', ext: this.data.video.ext} ).url() );
                this.node.querySelector('video').setAttribute( "poster", route( 'asset', { file_name: this.data.poster.file_name, preset: 'original', ext: this.data.poster.ext} ).url() );
	}
}
