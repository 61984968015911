import { Model, View } from "@lernetz/ts-lib";

import template from './ContentBlock.html';

import { Asset } from "../../../interfaces/Asset";

export class ContentBlock extends Model{
    image: Asset;
    preset: string;
    
    constructor( dto ) {
        super({  
            ...dto });
        
            if (this.image) {
                this.preset = (this.image.ext === 'svg' || this.image.ext === 'gif') ? 'original' : 'medium';
            }
    }
} 

export class ContentBlockView extends View<ContentBlock>{
    constructor(){
        super({
            template:template
        });
        
    }

    init() {
        
    }
}