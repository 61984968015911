import { Model, View } from "@lernetz/ts-lib";
import template from "./Carousel.html";
import { dependProperty } from "@lernetz/ts-lib/src/Observer";
//import { Carousel } from '@lernetz/ui-components/src/carousel/Carousel'
import {Asset} from '../../../interfaces/Asset';
import { route } from '@lernetz/ts-lib/src/Setup';
import { AudioPlayerItem } from "../AudioPlayer/AudioPlayerItem";



 interface item extends Model  {
    displayNavi: boolean;
    index:number;
}


export class Carousel extends Model {
    
    background: Asset;
    slides: item[   ];
    displayNavi: boolean;
    interval: number;
    isPlaying: boolean;
    currentIndex: number;
 
    public constructor( dto? ) {
         super({
            displayNavi: false, 
            currentIndex: 0,
             slides: [],
             isPlaying: false,
             ...dto
         });

         
         this.displayNavi = this.slides.length > 1 ? true : false;

         this.slides.forEach( item => {
            item.displayNavi = this.displayNavi;
         });

         if (this.slides.length > 1 ) {
             this.play();
         }
    }

    goTo( index) {
        this.currentIndex = index;
    }

     nextIndex() {
        return  this.currentIndex < this.slides.length -1 ? this.currentIndex+1 : 0;
    }

     previousIndex() {
        return this.currentIndex > 0 ? this.currentIndex-- : this.slides.length;
    }


    play() {
        this.isPlaying = true;
        this.currentIndex = this.nextIndex();
        this.interval = setInterval( () => {
            this.currentIndex = this.nextIndex();
        }, 5000)
    }

    pause() {
        this.isPlaying = false;
        clearInterval( this.interval );
    }


    nextClicked() {
        this.currentIndex = this.nextIndex();
        this.pause();
    }

    previousClicked() {
        this.currentIndex = this.previousIndex();
        this.pause();
    }



}


export class CarouselView extends View<Carousel> {

    public constructor( dto? ) {
        super( { 
            template: template,
            ...dto
        });

        
    }

    init() {

        if (this.data.background) {
            this.node.setAttribute('style', 'background-image:url( ' +  route( 'asset', {file_name: this.data.background.file_name, ext: this.data.background.ext, preset: 'original'}).url() + ' ) ');
        }

        
        
    }



}