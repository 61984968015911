import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import { shuffle } from '@lernetz/ts-lib/src/js';

import template from './FadingImages.html';

interface Image {
    active: boolean;
    image: unknown;
}

export class FadingImages extends Model {

    images: Image[];
    current: Image;

    constructor( dto ) {
        super({ images: [], ...dto });
    }

    setNextElement() {
        const currentIndex = this.images.indexOf( this.current );
        const nextIndex = ( currentIndex + 1 ) % this.images.length;
        const next = this.images[ nextIndex ];

        this.current = next;
        for( const image of this.images ) {
            image.active = ( image === this.current );
        }
    }
}

export class FadingImagesView extends View<FadingImages> {

    constructor(config?: ViewConfig<FadingImages>) {

        super({ template, data: new FadingImages({}), ...config });
    }

    init() {
        shuffle( this.data.images );

        this.data.setNextElement();

        const PAUSE_DURATION = 2000, FADE_DURATION = 3000;

        let interval: ReturnType<typeof setInterval>;
        const setNextElement = () => {
            clearInterval( interval );
            interval = setInterval( setNextElement, FADE_DURATION + PAUSE_DURATION );
            this.data.setNextElement();
        };
        interval = setInterval( setNextElement, PAUSE_DURATION + FADE_DURATION / 2 );
    }
}
