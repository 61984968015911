import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import template from './SpTitle.html';

export class SpTitle extends Model {

    title: string;
    size: string;
    position: string;
    margins: {};

    constructor( dto ) {
        super({ ...dto });

        this.margins = {
            'h1' : 'spacer-box -m-bottom-xxxl', //64
            'h2' : 'spacer-box -m-bottom-xl', //32
            'h3' : 'spacer-box -m-bottom-l', //24
        }
    }
}

export class SpTitleView extends View<SpTitle> {

    constructor(config?: ViewConfig<SpTitle>) {
        super({ template, ...config });
    }

    async init() {
    }

}
