import { Model, View } from '@lernetz/ts-lib';
import primaryTemplate from './primary.html';
import secondaryTemplate from './secondary.html';
import tertiaryTemplate from './tertiary.html';

export type SpLinkBaseStyle = 'primary' | 'secondary' | 'tertiary';

export abstract class SpLinkBase<TIcon extends string> extends Model {

    public icon?: TIcon;
    public style?: SpLinkBaseStyle;
    public target?: string;

    constructor( dto ) {
        super({ ...dto });
    }

    public abstract get defaultStyle(): SpLinkBaseStyle;

    public abstract get href(): string;
}

export class SpLinkBaseView<TSpLink extends SpLinkBase<any>> extends View<TSpLink> {

    private static getTemplateFor( data?: SpLinkBase<any> ) {
        switch( data?.style || data?.defaultStyle ) {
            case 'primary':
                return primaryTemplate;
            case 'secondary':
                return secondaryTemplate;
            case 'tertiary':
                return tertiaryTemplate;
        }
    }

    constructor( config: { data: TSpLink } ) {
        super({ template: SpLinkBaseView.getTemplateFor( config.data ) });
    }

    async init() {
    }

}
