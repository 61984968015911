import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import { route } from '@lernetz/ts-lib/src/Setup';
import  template  from './NewsletterElement.html';
import { Asset } from '../../../interfaces/Asset';


export class NewsletterElement extends Model {
    
    background_image_big: Asset;
    background_image_small: Asset;
    backgroundColor: string;
    subscribed: boolean;
    tag: string;
    text: string;

    constructor( dto ) {
        super({  
            ...dto });
    }
}

export class NewsletterElementView extends View<NewsletterElement> {
    
    constructor(config?: ViewConfig<NewsletterElement>) {
        super({ template, ...config });
    }

    init() {
        if (this.data.background_image_big) {
            this.node.setAttribute('style', 'background-image:url( ' +  route( 'asset', {file_name: this.data.background_image_big.file_name, ext: this.data.background_image_big.ext, preset: 'original'}).url() + ' ) ');
        }
        if (this.data.backgroundColor)  {
            this.node.setAttribute('style','background-color:' + this.data.backgroundColor);
        }

        if (this.data.background_image_small) {
            this.node.querySelector('.inner').setAttribute('style', 'background-image:url( ' +  route( 'asset', {file_name: this.data.background_image_small.file_name, ext: this.data.background_image_small.ext, preset: 'original'}).url() + ' ) ');
        }

        let input = this.node.querySelector('.input') as HTMLFormElement;
        input.addEventListener( 'focus', this.onFocus.bind(this))
        input.addEventListener( 'keydown', this.onKeydown.bind(this))


    }


    onFocus( e ){
        if ( e.target.textContent === 'E-Mail' ) {
            e.target.textContent = '';
        }
        
        this.node.querySelector('.message').textContent = '';
        this.node.querySelector('.message').classList.add('_hidden');
    }

    onKeydown(e) {
        if ( e.which === 13 || e.keyCode === 13 ) {
            this.submit();
            e.preventDefault();
        }
        
    }

    submit() {

        if (this.data.subscribed === true ) {
            this.node.querySelector('.message').textContent = 'Vielen Dank. Sie haben sich bereits einen Newsletter registriert';
            return;
        }

        let mail = this.node.querySelector('.input').innerHTML;
         
        if ( this.validateEmail(mail) ) {
            let data = {email: mail, tag: this.data.tag };
            route('subscribe').send(JSON.stringify( data ) ).then( (response:{status: boolean})=> {
                if ( response.status ) {
                    this.node.querySelector('.message').textContent = 'Anmeldung erfolgreich..';
                    this.data.subscribed = true;
                    this.node.querySelector('.message').classList.remove('_hidden')
                }
                else {
                    this.node.querySelector('.message').textContent = 'Irgendetwas ist schief gelaufen ...';
                    this.node.querySelector('.message').classList.remove('_hidden')
                }
            })
        }
        else {
            this.node.querySelector('.message').textContent = 'Bitte gültige E-mail Adresse angeben';
            this.node.querySelector('.message').classList.remove('_hidden')
        }

        
    }


    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }



}