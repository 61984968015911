import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './AboutBox.html';

import  LinkItemTemplate  from './LinkItem.html';
import DownloadItemTemplate from './DownloadItem.html';


export class AboutBox extends Model {
    
    opened: boolean;
    size: number;

    constructor( dto ) {
        super({  
            opened: false,
            ...dto 
        });
    }
}

export class AboutBoxView extends View<AboutBox> {
    
    constructor(config?: ViewConfig<AboutBox>) {
        super({ template, ...config });
        this.registry.add( 'App\\LinkItem', () => new View({template:LinkItemTemplate } ) );
        this.registry.add( 'App\\DownloadItem', () => new View({template:DownloadItemTemplate } ) );
    }

    init() {
        //get the height of the dropdown container... TODO
        this.data.opened = false;
        setTimeout( ()=> {
            this.node.querySelector('.container').setAttribute('style', 'max-height: none') ;
            this.data.size= this.node.querySelector('.container').scrollHeight
            this.node.querySelector('.container').setAttribute('style', 'max-height:0px' ) ;
        }, 100)

        this.data.propertyChange.filter(({ name }) => name === 'opened' ).subscribe(() => {
            if (this.data.opened === true ) {
                this.node.querySelector('.container').setAttribute('style', 'max-height:' + this.data.size + 'px' ) ;
            }
            else {
                this.node.querySelector('.container').setAttribute('style', 'max-height:0px') ;
            }
        });
    }


}