import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import template from './SpParagraph.html';

export class SpParagraph extends Model {

    text: string;
    size: string;
    position: string;

    constructor( dto ) {
        super({ ...dto });

        // TODO: This is meant to be for back-compat, but may not actually be necessary.
        if( this.size === '-p' ) this.size = '-para';
    }
}

export class SpParagraphView extends View<SpParagraph> {

    constructor(config?: ViewConfig<SpParagraph>) {
        super({ template, ...config });
    }

    async init() {
    }

}
