import { Model, Signal, View } from '@lernetz/ts-lib';
import { route } from '@lernetz/ts-lib/src/Setup';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './AudioPlayerItem.html';

import { Asset } from '../../../interfaces/Asset';
import { AudioService } from '../../../services/AudioService';

export class AudioPlayerItem extends Model {
    
    state: string;
    audio: Asset;
    title: string;
    duration: string;
    progress: number;
    currentTime: number;
    currentTimeString: string;
    loaded: boolean;

    audioView: HTMLAudioElement;

    constructor( dto ) {
        super({  
            state: "stopped",
            currentTimeString: "00:00",
            progress: 0,
            loaded: false,
            ...dto 
        });
    }

    public registerAudio() {
        this.audioView = new Audio( route( 'asset', {file_name: this.audio.file_name, ext: 'mp3', preset: 'original'} ).url() );
        AudioService.register(this);
    }

    // public forward() {
    //     this.audioView.currentTime +=  10; 
    // }
    // public rewind() {
    //     this.audioView.currentTime -=  10; 
    // }

    public setProgress(event) {
        if ( !this.audioView ) {
            this.registerAudio();
        }
        let newProgress = Math.round( (  event.offsetX / event.srcElement.offsetWidth  ) * 100 );
        AudioService.setCurrentTime( this,  Math.round(newProgress ));
    }

    public toggleAudio() {
        if ( !this.audioView ) {
            this.registerAudio();
        }

        if (this.state === 'stopped') {
            AudioService.play( this );
        }
        else {
            AudioService.pause( this );
        }
    }

}

export class AudioPlayerItemView extends View<AudioPlayerItem> {
    
    constructor(config?: ViewConfig<AudioPlayerItem>) {
        super({ template, ...config });
    }

    init() {

    }


}