import { Model, View } from "@lernetz/ts-lib";
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import { route } from "@lernetz/ts-lib/src/Setup";

import template from './Iframely.html';
import { Asset } from "../../interfaces/Asset";

export class Iframely extends Model{
    url: string;
    
}

export class IframelyView extends View<Iframely>{
    constructor(config?: ViewConfig<IframelyView>){
        super({
            template
        });
    }

    init() {
        if (!this.data.url) return;
        route( 'iframely', {url: encodeURIComponent( this.data.url ) })
            .method('get')
            .send({})
            .then( function( data: {html:string} ) {
                if (data.html) {
                    this.node.innerHTML = data.html;
                }
                else {
                    this.node.innerHTML = `<iframe style="width:100%; height:500px;" frameborder="0" src="${this.data.url}"></iframe>`;
                }
            }.bind(this))
    }
}