import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import { asset } from "@lernetz/ts-lib/src/Setup";

import  template  from './SinglePage.html';

export class SinglePage extends Model {
    
    selectedPage: string;
    slug: string;

    constructor( dto ) {
        super({  ...dto });
        
        this.selectedPage = this.slug;
    }
}

export class SinglePageView extends View<SinglePage> {
    
    constructor(config?: ViewConfig<SinglePage>) {
        super({ template, ...config });
    }

    init() {
        //scroll To anchor if there is one
        setTimeout( () => {
            var currentUrl = document.URL,
            urlParts   = currentUrl.split('#');
            if (urlParts[1]) {
                let node = this.node.querySelector('#' + urlParts[1] ) as HTMLElement;
                if (node) {
                    window.scrollTo({
                        behavior: 'smooth',
                        top: node.offsetTop - 80,
                    });
                }
                
            }
        }, 100)
    }


}