import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import { route } from '@lernetz/ts-lib/src/Setup';


import template from './downloadItem.html';


export class DownloadItemView extends View<Model> {

    constructor(config?: ViewConfig<Model>) {
        super({ template, ...config });

    }

    init() {

    }

    download( file ) {
        const plausible: any = window[ 'plausible' ];
        if( plausible ) {
            plausible( 'File Download', {
                props: {
                    'url': route( 'file', { 'id': file.id, 'filename': file.name } ).url(),
                },
            } );
        }
        // unprotected route for files which are public
        if (file.public === true)  {
            window.location.href = route('asset', {file_name: file.file_name, preset: 'original', ext: file.ext } ).url()
        }
        else {
            window.location.href = route('file', {id: file.file_name,  filename: file.name } ).url();
        }
    }
}
