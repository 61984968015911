import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import { route, setup } from '@lernetz/ts-lib/src/Setup';
import {HttpRequest}  from '@lernetz/ts-lib/src/Request';

import  template  from './LandingPage.html';


export class LandingPage extends Model {
    

    subscribed: boolean;
    constructor( dto ) {
        super({  subscribed: false, ...dto });
    }



}

export class LandingPageView extends View<LandingPage> {
    
    constructor(config?: ViewConfig<LandingPage>) {
        super({ template, ...config });
    }

    init() {
        let input = this.node.querySelector('.input') as HTMLFormElement;
        input.addEventListener( 'focus', this.onFocus.bind(this))
        input.addEventListener( 'keydown', this.onKeydown.bind(this))
        
    };
    
    onFocus( e ){
        if ( e.target.textContent === 'E-Mail' ) {
            e.target.textContent = '';
        }
        this.node.querySelector('.message').textContent = '';
        this.node.querySelector('.message').classList.add('_hidden');
    }

    onKeydown(e) {
        if ( e.which === 13 || e.keyCode === 13 ) {
            this.submit();
            e.preventDefault();
        }
        
    }

    submit() {

        if (this.data.subscribed === true ) {
            this.node.querySelector('.message').textContent = 'Vielen Dank. Sie haben sich bereits für einen Newsletter registriert.';
            return;
        }

        let mail = this.node.querySelector('.input').innerHTML;
         
        if ( this.validateEmail(mail) ) {
            let data = {email: mail, tag: 'Landingpage bis 24 August 2020'};
            route('subscribe').send(JSON.stringify( data ) ).then( (response:{status: boolean})=> {
                if ( response.status ) {
                    this.node.querySelector('.message').textContent = 'Anmeldung erfolgreich.';
                    this.data.subscribed = true;
                    this.node.querySelector('.message').classList.remove('_hidden')
                }
                else {
                    this.node.querySelector('.message').textContent = 'Irgendetwas ist schief gelaufen...';
                    this.node.querySelector('.message').classList.remove('_hidden')
                }
            })
        }
        else {
            this.node.querySelector('.message').textContent = 'Bitte gültige E-mail Adresse angeben.';
            this.node.querySelector('.message').classList.remove('_hidden')
        }

        
    }


    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

}