import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import { route } from '@lernetz/ts-lib/src/Setup';


import  template  from './SimpleSlide.html';
import { ImageView as ImageHelper } from '@lernetz/ts-lib/src/components/Image';


import {Asset} from '../../../interfaces/Asset';

export class SimpleSlide extends Model {
    
    slides: [];
    background: Asset;
    backgroundColor: string;
    image: Asset;
    preset: string;
    index:number;
    

    constructor( dto ) {
        super({  ...dto });
        
        if (this.image) {
            this.preset = ( this.image.ext === 'gif' || this.image.ext === 'svg'  ) ? 'original' : 'carousel';
        }

    }
}

export class SimpleSlideView extends View<SimpleSlide> {
    
    constructor(config?: ViewConfig<SimpleSlide>) {
        super({ template, ...config });
        //this.registry.add( 'Image', () => new ImageHelper( ) );
    }

    init() {


        if (this.data.background) {
            this.node.setAttribute('style', 'background-image:url( ' +  route( 'asset', {file_name: this.data.background.file_name, ext: this.data.background.ext, preset: 'original'}).url() + ' ) ');
        }

        if (this.data.backgroundColor) {
            this.node.setAttribute('style', 'background-color:' + this.data.backgroundColor );
        }


    }


}