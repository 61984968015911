import { Model, Signal, View, setup } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './ContainerPage.html';

// import headerTemplate from './Header.html';
import footerTemplate from './Footer.html';
import { data } from "@lernetz/ts-lib/src/Setup";

// import  customerHeaderTemplate  from './CustomerHeader.html';
// import  guestHeaderTemplate  from './GuestHeader.html';

import headerTemplate from './Header.html';
import { Header } from './Header';

export class ContainerPage extends Model {
    
    
    constructor( dto ) {
        super({   ...dto });
        
    }
}

export class ContainerPageView extends View<ContainerPage> {
    
    constructor(config?: ViewConfig<ContainerPage>) {
        super({ data: new ContainerPage( {  } ), template, ...config });
        // let headerTemplate =  data('isCustomer') ? customerHeaderTemplate : guestHeaderTemplate;
        //this.registry.add( 'Header', () => new View({ data: new Header({ isCustomer: data('isCustomer'), hamburgerOpen: false }), template:headerTemplate } ) );
        this.registry.add( 'Header', () => new View({ data: new Header({  hamburgerOpen: false }), template:headerTemplate } ) );
        
        this.registry.add( 'Footer', () => new View({  template:footerTemplate } ) );
    }

    init() {
        
    }


}