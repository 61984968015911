import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import { data, route } from '@lernetz/ts-lib/src/Setup';
import  template  from './TutorialPage.html';
import { SongPage } from '../SongPage/SongPage';

import CustomerSongNavigation from './CustomerSongNavigation.html';
import GuestSongNavigation from './GuestSongNavigation.html';
import TagTemplate from './Tag.html';

import Header from '../../../services/Header';

export interface element {
    modelName: string;
    id: string;
}


export class TutorialPage extends Model {

    elements: element[];
    shortCode: string;
    slug: string;
    songSlug: string;
    title: string;
    description: string;
    song: SongPage;
    shareMenuClose: boolean;
    id: string;
    showSticky: boolean;
    type: string;
    originalSongId: string;
    mobileNavigationOpen: boolean;
    showCopySuccess: boolean;
    tags: string[];
    favorite: boolean;
    favoriteUpdatedAt: number;
    public: boolean; //only used for general tutorials in guest-mode

    constructor( dto ) {
        super({
            shareMenuClose: true,
            showSticky: false,
            mobileNavigationOpen: false,
            showCopySuccess: false,
            originalSongId: null,
            // favorite: true,
            ...dto
        });

        if (this.elements) {
            let original = this.elements.find( (element) => element.modelName === 'App\\AboutBox');
            this.originalSongId = original ? original.id : null;
        }

    }

    toggleFavorite( e:Event ) {
        //disable navigation behaviour
        e.preventDefault();
        e.stopPropagation();

        this.favorite = !this.favorite;
        this.favoriteUpdatedAt = Date.now();

        let data = {
            metadata: { modelName: 'App\\Tutorial', id: this.id },
            data: {
                favorite: this.favorite,
                favoriteUpdatedAt: this.favoriteUpdatedAt,
            }
        }

        route( 'userData.update' )
            .send( JSON.stringify( data ) );
    }

    get hasRelatedContent() {
        return this.elements.find( model => model.modelName === 'App\\RelatedContent' ) ? true : false
    }


}

export class TutorialPageView extends View<TutorialPage> {

    constructor(config?: ViewConfig<TutorialPage>) {
        super({ template, ...config });
        let customerSongNavigation =  new View({ template: CustomerSongNavigation } )
        let guestSongNavigation =  new View({ template: GuestSongNavigation } )
        this.registry.add( 'SongNavigation', () => (data('isCustomer') || this.data.song.public )? customerSongNavigation : guestSongNavigation );
        this.registry.add( 'Tag', () => new View({template:TagTemplate } ) );
    }

    init() {

        //node is not rendered yet...
        setTimeout( ()=> {
            //make header sticky and remove hamburger menu on mobile down-scrolling
            new Header( this );
        }, 100)

    }


    scrollTo(  ){

        //todo: calculate the visibillity/height of the 2 headers...
        let target = document.querySelector('.about-box') as HTMLElement;
        window.scrollTo({
            behavior: 'smooth',
            top: target.offsetTop - 80,
        });
    }

    scrollToTutorials(  ){

        //todo: calculate the visibillity/height of the 2 headers...
        let target = document.querySelector('.related-content') as HTMLElement;
        window.scrollTo({
            behavior: 'smooth',
            top: target.offsetTop - 80,
        });
    }




    copy2clipboard() {
        let tempInput = document.createElement("input");
        tempInput.value = route('student', {shortCode: this.data.shortCode}).url();
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        this.data.showCopySuccess = true;
    }


}
