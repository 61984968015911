import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './TutorialPreview.html';

import {SongPage} from '../Pages/SongPage/SongPage';

export class TutorialPreview extends Model {

    route: string;
    song: SongPage;
    formatedPublicationDate: string;

    constructor( dto ) {
        super({ route:null,
                song: null,

             ...dto });
    }

}

export class TutorialPreviewView extends View<TutorialPreview> {

    constructor(config?: ViewConfig<TutorialPreview>) {
        super({ template, data: new TutorialPreview({}), ...config });
    }

    init() {

    }



}
