import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './ModalElement.html';
import { TutorialPage } from 'typescript/components/Pages/TutorialPage/TutorialPage';


export class ModalElement extends Model {
    
    content: TutorialPage;
    closeModal:Signal<{}>;

    constructor( dto ) {
        super({  
            
            ...dto });

            this.closeModal = new Signal();
    }
    
    
}

export class ModalElementView extends View<ModalElement> {
    
    constructor(config?: ViewConfig<ModalElement>) {
        super({ data: new ModalElement( { } ), template, ...config });
    }

    init() {
        


        // todo: this is not called when content changes for the first time
        this.data.propertyChange.filter(({ name }) => name === 'content' ).subscribe((e) => {
            document.body.style.position =  'fixed';
            document.body.style.width =  '100%';
        });

        this.data.closeModal.subscribe( ()=> {
            document.body.style.position = 'relative';
        })

    }


}