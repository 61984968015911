import { Model, View } from "@lernetz/ts-lib";

import template from './TextImage.html';
import { Asset } from "../../../interfaces/Asset";

export class TextImage extends Model{
    backgroundImage: string;
    animation: string;
    wrap: boolean;
    reverse: boolean;
    linkImage: boolean;
    style: string;

    constructor( dto ) {
        super({  ...dto });
        this.backgroundImage = (this.animation !== 'none') ? '/image/' + this.animation + '.svg': '';
    }
}

export class TextImageView extends View<TextImage>{
    constructor(){
        super({
            template:template
        });
    }
}
