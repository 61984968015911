import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';
import template from './SpImage.html';

export class SpImage extends Model {



    constructor( dto ) {
        super({ ...dto });
    }
}

export class SpImageView extends View<SpImage> {

    constructor(config?: ViewConfig<SpImage>) {
        super({ template, ...config });
    }

    async init() {
    }

}
