import { Model, Signal, View } from '@lernetz/ts-lib';
import { ViewConfig } from '@lernetz/ts-lib/src/view/View';

import  template  from './LibraryPreview.html';


export class LibraryPreview extends Model {
    
    text: string;

    constructor( dto ) {
        super({  ...dto });
        
    }
}

export class LibraryPreviewView extends View<LibraryPreview> {
    
    constructor(config?: ViewConfig<LibraryPreview>) {
        super({ template, ...config });
    }

    init() {
        
    }


}