import { route } from '@lernetz/ts-lib/src/Setup';
import type { Asset } from '../../../interfaces/Asset';
import { SpLinkBase } from '../SpLink/SpLinkBase';
import type { SpLinkBaseStyle } from '../SpLink/SpLinkBase';

export class SpDownload extends SpLinkBase<'' | 'download'> {

    public readonly file: Asset;

    public get defaultStyle(): SpLinkBaseStyle {
        return 'primary';
    }

    public get href() {
        const file_name = this.file?.file_name;
        return file_name ? route( 'download', { file_name } ).url() : '#';
    }
}
