
import { Model, Signal, View } from '@lernetz/ts-lib';


export default class Header {

    view: View;

    public constructor( view ) {
        this.view = view;



        //show tooltip on page load
        let tooltip = this.view.node.querySelector('.scrollto-element .tooltip');
        if (tooltip) {
            setTimeout( ()=> {
                tooltip.classList.add('-active');
            }, 10)

            setTimeout( ()=> {
                tooltip.classList.remove('-active');
            }, 1000)
        }



        let lastScrollTop = 0;
        let offset = (this.view.data.type === 'general') ? 134 : 60;

        window.addEventListener('scroll', function(e) {

            //remove mainheader on downscrolling on mobile
            if (window.pageYOffset > offset) {
                let st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop){
                    // downscroll
                    this.view.node.classList.add('-downscrolling')
                } else {
                    // upscroll
                    this.view.node.classList.remove('-downscrolling')
                }
                lastScrollTop = st <= 0 ? 0 : st;
            }


            if ( window.pageYOffset < offset ) {
                this.view.data.showSticky = false;
            }
            else {
                this.view.data.showSticky = true;
            }

        }.bind(this) );
    }

}
